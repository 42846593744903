import rightImg from "images/about-hero-right.png";
import rightImg2 from "images/about-hero-right2.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionHero4 from "components/SectionHero/SectionHero";
import HomeAboutSection from "containers/PageAbout/HomeAboutSection";
import WhyLevel305 from "containers/why305/why305";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";
import readytodrive from "images/readytodrive.png";
import { useEffect } from "react";

function PageHome3() {
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }, []);

  return (
    <div className="nc-PageHome3 relative overflow-hidden mt-4">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 md:text-start text-center">
        <div className="container">
          <SectionHero4 className="" />
        </div>
      </div>
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
      </div>

      <div className="relative p-8 md:p-16">
        <HomeAboutSection rightImg={rightImg2} />
      </div>
      <div className=" relative" style={{ padding: "0px" }}>
        {/* ======== BG GLASS ======== */}
        {/* <BgGlassmorphism /> */}
        <WhyLevel305 />
      </div>
      <div className="bg-[#EDEFFF] mt-12 mx-0 md:mx-12 rounded-[0rem] md:rounded-[2.5rem]">
        <div className="relative py-16 mb-20 lg:mb-36">
          {/* <BackgroundSection /> */}
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
      </div>
      <div className="bg-[#EDEFFF] my-12 mx-0 md:mx-12 rounded-[0rem] md:rounded-[2.5rem]">
        <div className="p-8 md:p-16">
          {/* <BackgroundSection /> */}
          <ReadytoDrive
            rightImg={readytodrive}
            heading="Book your ride today!"
            btnText=""
            subHeading="If you are just arriving we can pick you up at no additional cost. Live the experience from the moment you land and we will pick you up back wherever YOU want."
          />
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
