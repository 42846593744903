import { FC } from "react";
import imagePng from "images/hero-right.png";
import { HeroSearchFormMobile } from "components/HeroSearchForm/HeroSearchFormMobile";
import { HeroSearchForm } from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHero4Props {
  className?: string;
}

const SectionHero4: FC<SectionHero4Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-bold text-3xl md:text-6xl !leading-[114%] text-primary-500 italic">
            Hassle Free Car Rentals <br />
            <span className="text-black not-italic">South Florida</span>
          </h2>
          <div className="w-full lg:hidden md:w-[480px] xl:w-[480px] mx-auto z-50">
            <HeroSearchFormMobile />
          </div>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-60 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero4;
